import {
	Button,
	Flex,
	HStack,
	Stack,
	Menu,
	MenuButton,
	MenuItem,
	MenuList,
	Text,
	useColorMode,
	useMediaQuery,
} from '@chakra-ui/react';
import {
	IconArrowDown,
	IconClose,
	IconPublish,
	IconSave,
} from '../../../assets/Icons';
import TabComponent from './TabComponent';
import { useNavigate } from 'react-router-dom';
import { PrivateRoutes } from '../../../routes/Routes';
import { useTranslation } from 'react-i18next';
import TooltipComponent from '../../../components/TooltipComponent';

const TabsHeaderComponent = ({
	state,
	handleUpdate,
	currentTab,
	publishLoading,
	draftLoading,
	tabChangeDisabled,
	handleSave,
	onSelectTab,
	tabs,
}) => {
	const { t } = useTranslation();

	const navigate = useNavigate();
	const { colorMode } = useColorMode();
	const iconsColor = colorMode === 'dark' ? 'white' : 'black';
	const [isTablet] = useMediaQuery('(max-width: 1140px)');
	const [isMobile] = useMediaQuery('(max-width: 750px)');

	return (
		<Flex
			flexDirection={isTablet ? 'column' : 'row'}
			justify={isTablet ? 'center' : 'space-between'}
			pb='30px'
			gap={isTablet ? '10px' : '0'}
		>
			{!isTablet && (
				<Button
					variant='secondary'
					fontSize='13px'
					gap='5px'
					onClick={() => navigate(`/${PrivateRoutes.Admin}`)}
				>
					<IconClose boxSize='14px' />
					{t('addProperty.adminPanel.cancel')}
				</Button>
			)}
			{!isTablet && (
				<Flex
					h='40px'
					alignItems='center'
					bg={
						colorMode === 'dark'
							? 'transparencyWhite.100'
							: 'transparencyBlack.100'
					}
					borderRadius='5px'
					gap='5px'
					p={isTablet ? '0px' : '10px'}
				>
					{tabs.map((tab, index) => (
						<TabComponent
							key={index}
							index={index}
							icon={
								<tab.icon boxSize='14px' color={iconsColor} />
							}
							title={t(`addProperty.tabs.${tab.title}`)}
							tabChangeDisabled={tabChangeDisabled}
							isActive={currentTab.title === tab.title}
							onSelectTab={() => onSelectTab(tab)}
						/>
					))}
				</Flex>
			)}
			<Flex gap='10px' justify={isTablet ? 'space-between' : 'center'}>
				{isTablet && (
					<Button
						variant='secondary'
						fontSize='12px'
						gap={isMobile ? '2px' : '5px'}
						onClick={() => navigate(`/${PrivateRoutes.Admin}`)}
					>
						<IconClose boxSize={isMobile ? '10px' : '14px'} />
						{t('addProperty.adminPanel.cancel')}
					</Button>
				)}
				<HStack>
					{state?.projectId ? (
						<Button
							variant={
								tabChangeDisabled ? 'mainDisabled' : 'main'
							}
							gap='5px'
							onClick={() => handleUpdate(true)}
							disabled={draftLoading || publishLoading}
							isLoading={publishLoading}
						>
							<IconPublish boxSize='14px' />
							{t('addProperty.adminPanel.update')}
						</Button>
					) : (
						<>
							<Button
								variant={
									tabChangeDisabled
										? 'secondaryDisabled'
										: 'secondary'
								}
								fontSize='13px '
								gap='5px'
								px={isMobile ? 2 : 4}
								onClick={() => handleSave(true)}
								disabled={draftLoading || publishLoading}
								isLoading={draftLoading}
							>
								<IconSave boxSize='14px' />
								{t('addProperty.adminPanel.draft')}
							</Button>

							<Stack position='relative'>
								<Button
									variant={
										tabChangeDisabled
											? 'mainDisabled'
											: 'main'
									}
									gap='5px'
									onClick={() => handleSave(false)}
									disabled={draftLoading || publishLoading}
									isLoading={publishLoading}
								>
									<IconPublish boxSize='14px' />
									{t('addProperty.adminPanel.publish')}
								</Button>
								<Text
									fontSize='12px'
									color='gray.500'
									position='absolute'
									mt='45px'
									textWrap='nowrap'
									whiteSpace='nowrap'
								>
									Min. 0.0001 ETH
								</Text>
							</Stack>
						</>
					)}
				</HStack>
			</Flex>
			{isTablet && (
				<Flex
					h='40px'
					alignItems='center'
					bg={
						colorMode === 'dark'
							? 'transparencyWhite.100'
							: 'transparencyBlack.100'
					}
					borderRadius='5px'
					gap='5px'
					p={isTablet ? '0px' : '10px'}
				>
					<Menu>
						<MenuButton
							w={'100%'}
							p='10px'
							fontWeight='200'
							borderRadius='5px'
							textAlign='start'
							bg={
								colorMode === 'dark'
									? 'transparencyWhite.100'
									: 'transparencyBlack.100'
							}
							_hover={{
								bg:
									colorMode === 'dark'
										? 'transparencyWhite.200'
										: 'transparencyBlack.200',
							}}
							_active={{
								border: '0.5px solid',
								borderColor:
									colorMode === 'dark'
										? 'brand.200'
										: 'brand.500',
							}}
							rightIcon={<IconArrowDown boxSize='12px' />}
							as={Button}
						>
							<Text fontSize='13px'>
								{t(`addProperty.tabs.${currentTab.title}`)}
							</Text>
						</MenuButton>

						<MenuList fontSize='12px' zIndex={10}>
							{tabs.map((tab, i) => (
								<>
									<MenuItem
										key={i}
										value={''}
										onClick={() => onSelectTab(tab)}
									>
										<HStack gap={'10px'}>
											<tab.icon
												boxSize='14px'
												color={iconsColor}
											/>
											<Text>
												{t(
													`addProperty.tabs.${tab.title}`,
												)}
											</Text>
											{i === 3 && (
												<TooltipComponent
													label='This parameter outlines the timeline of the project, detailing each stage from start to finish. It provides a structured schedule of activities and milestones, such as financing, acquisition, setup, rental operations, or eventual sale. The timeline varies depending on the type of project, adapting to its specific processes and objectives. This helps ensure clarity and alignment for all stakeholders.'
													placement='auto-start'
												/>
											)}
										</HStack>
									</MenuItem>
								</>
							))}
						</MenuList>
					</Menu>
				</Flex>
			)}
		</Flex>
	);
};
export default TabsHeaderComponent;
