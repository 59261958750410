import { Flex, Text, useColorMode, useTheme } from '@chakra-ui/react';
import { formatOrDash } from '../../../../../../utils/utils';

const InfoSummaryComponent = ({
	header,
	amount,
	total,
	end,
	string,
	porcent,
	month,
}) => {
	const theme = useTheme();

	const { colorMode } = useColorMode();

	const borderColor =
		colorMode === 'dark'
			? theme.colors.transparencyWhite[200]
			: theme.colors.transparencyBlack[200];
	return (
		<Flex
			justifyContent='space-between'
			borderBottom={!total && !end && `1px solid ${borderColor}`}
			py='14px'
		>
			<Text
				variant={!total && 'gray'}
				fontSize={total ? '14px' : '12px'}
				fontWeight={total ? '700' : '400'}
			>
				{header}
			</Text>
			<Text
				fontSize={total ? '14px' : '12px'}
				fontWeight={total ? '700' : '400'}
				color={colorMode === 'dark' ? 'white' : 'black'}
			>
				{(string && amount) ||
					(porcent
						? `${amount.toFixed(2)} %`
						: month
							? `${parseInt(amount)} month(s)`
							: formatOrDash(amount, 'USD'))}
			</Text>
		</Flex>
	);
};

export default InfoSummaryComponent;
